.homePagePageContainer {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  .homePageContainerCol {
    width: fit-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .homePageContainer {
      width: 100%;
      margin: 0 auto;
      max-width: 520px;
      // min-height: 400px;
      padding: 10px;

      h1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 28px;
        align-items: center;
        text-align: center;
        color: #000000;
      }

      .paraContainer {
        margin-bottom: 20px;
        p {
          font-family: "Inter";
          font-style: normal;
          margin: 0px;
          font-weight: 400;
          font-size: 16px;
          line-height: 25px;
          color: #2e2e2e;
        }
      }

      .boxesContiner {
        display: unset;
        .formData {
          :global(.ant-input) {
            border: 1px solid #00b840;
            font-family: "Inter";
          }
          :global(.ant-form-item-explain-error) {
            text-align: left;
            font-size: 16px !important;
          }
          :global(.ant-form-item-label > label) {
            font-size: 18px;
          }
        }

        .nextButtonDiv {
          max-width: 62%;
          margin-top: 10px;
          display: flex;

          .nextButton {
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: center;
            &:disabled {
              background-color: gray;
            }

            & > svg {
              margin: 0 !important;
            }

            margin-top: 25px;
            height: 50px;
            width: 134px;
            border-radius: 5px;
            border: none;
            color: white;
            background-color: #00b840;
            font-family: Inter;
            font-size: 20px;
            font-weight: 500;
            cursor: pointer;
          }
        }

        :globbal(.ant-form-item-explain-error) {
          display: flex;
        }

        @media only screen and (max-width: 425px) {
          .nextButtonDiv {
            .nextButton {
              &:disabled {
                background-color: gray;
              }
              height: 40px;
              width: 114px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    height: max-content;
    margin-bottom: 50px;

    .homePageContainerCol {
      width: 80%;
    }
  }
  @media only screen and (max-width: 992px) {
    .homePageContainerCol {
      width: 80%;
    }
  }
}
.btnDiv {
  width: 100%;

  .twoBtnDiv {
    display: flex;
    padding-inline: 28px;

    justify-content: space-between;
    .goBtn {
      height: 50px;
      width: 134px;
      font-size: 20px;
      color: #2e2e2e;
      background-color: #e6e6e6;
      border-radius: 5px;
      cursor: pointer;
      border: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 5px;

      img {
        margin-top: 1px;
      }
    }
    .saveBtn {
      color: #2e2e2e;
      background-color: #e6e6e6;
      height: 50px;
      width: 134px;
      font-size: 20px;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }
  @media only screen and (max-width: 425px) {
    .twoBtnDiv {
      padding-inline: 20px;
      margin-bottom: 0;
      .goBtn {
        height: 40px;
        width: 114px;
      }
      .saveBtn {
        height: 40px;
        width: 114px;
      }
    }
  }
}
.btnDiv {
  margin: 0;
  width: 100%;
  position: absolute;
  bottom: 0px;

  .twoBtnDiv {
    padding-inline: 28px;
    width: 100%;
    background-color: #ffffff;
    border-top: 1px solid #e0e0e0;
    height: 90px;

    .goBackBtnDiv {
      padding-top: 22px;
      width: 100%;
      margin-left: 15px;

      .goBtn {
        height: 50px;
        width: 134px;
        color: #f8ebeb;
        background-color: #e6e6e6;
        border-radius: 5px;
        cursor: pointer;
        border: none;
        display: flex;
        font-size: 20px;
        flex-direction: row;
        // margin-top: 60px;
        justify-content: center;
        align-items: center;
        gap: 5px;

        img {
          margin-top: 1px;
        }
      }
    }
    .saveBtn {
      color: #2e2e2e;
      background-color: #e6e6e6;
      height: 50px;
      width: 134px;
      border: none;
      white-space: nowrap;
      cursor: pointer;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      font-size: 20px;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }
  @media only screen and (max-width: 425px) {
    .twoBtnDiv {
      padding-inline: 20px;
      margin-bottom: 0;
      .goBtn {
        height: 40px;
        width: 114px;
      }
      .saveBtn {
        height: 40px;
        width: 114px;
      }
    }
  }
}

.headerDesc {
  color: var(--text-in-field, #2e2e2e);
  font-family: Inter;
  font-size: 16px;
  line-height: 25px;
  text-align: start;
}

.emailInput {
  border-radius: 5px;
  border: 1px solid #e0e0e0 !important;
  font-size: 14px !important;

  &::placeholder {
    font-size: 14px;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1) !important;
    border: 1px solid #4096ff !important;
  }
}
