.table {
  display: grid;
  grid-template-rows: 55px 45px repeat(7, 65px) 65px;
  grid-template-columns: 1fr repeat(3, 150px);
  margin-bottom: 40px;

  border-radius: 10px;
  border: 1px solid var(--neutral-600, #eaebf0);
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);

  & > * {
    border-bottom: 1px solid var(--neutral-600, #eaebf0);
  }

  &Loader {
    grid-column: 1 / 5;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &Title {
    display: flex;
    align-items: center;
    padding: 0 24px;
    height: 100%;
    margin: 0;
    grid-column: 1 / 5;

    color: var(--Black, #000);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.1px;
  }

  &Head {
    color: var(--gray-50, #5f6d7e);

    padding: 12px 24px;
    margin: 0;

    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.1px;
  }

  &Item {
    margin: 0;
    padding: 22px 20px;

    color: var(--gray-50, #5f6d7e);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.1px;

    &Name {
      margin: 0;
      color: var(--Black, #000);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.1px;

      display: flex;
      align-items: center;
      gap: 16px;

      cursor: pointer;

      & > div {
        width: 32px;
        height: 32px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;
        background-color: #f6f6f6;
      }
    }
  }

  &Pagination {
    grid-column: 1 / 5;
    padding: 12px 24px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &Controlls {
      display: flex;
      align-items: center;
      gap: 6px;

      cursor: pointer;

      & > p {
        margin: 0;
        color: var(--gray-50, #5f6d7e);
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.1px;
      }
    }
  }
}
