.card {
  width: 100%;
  max-width: 470px;
  height: 200px;
  padding: 29px 26px;

  display: flex;
  flex-direction: column;

  background-color: #ffffff;

  &Heading {
    color: #2e2e2e;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;

    margin: 0 0 9px 0;
  }

  &SubHeading {
    color: #2e2e2e;
    font-weight: 500;
    line-height: 25px;

    margin: 0;
  }

  &Content {
    color: #858ba0;
    font-family: Inter;
    line-height: 25px;
    margin: 0;
  }

  &FooterText {
    margin-top: auto;
    padding-top: 12px;
    border-top: 1px solid #cecece;

    color: #00b840;
    font-family: Inter;
    font-weight: 500;
    line-height: 25px;

    cursor: pointer;

    &Empty {
      width: 100%;
      height: 25px;
      margin-top: auto;
      padding-top: 38px;

      border-top: 1px solid #cecece;
    }
  }
}
