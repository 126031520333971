.upgradePlanBarContainer {
  position: relative;
  z-index: 10;
  text-align: center;
  background: #ffffff;
  border-bottom: 1px solid #dde2e4;
  display: flex;
  justify-content: space-between;
  padding: 0px 24px;
  box-sizing: border-box;

  .closeImg {
    margin-top: 16px;
    width: 11px;
    height: 11px;
    cursor: pointer;
  }

  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
  }

  p {
    font-size: 14px;
    color: #2e2e2e;
    .upgradeContainer {
      display: flex;
      min-width: 110px;
      gap: 5px;

      img {
        margin-left: 10px;
      }

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #00b840;
        cursor: pointer;
        @media screen and (max-width: 420px) {
          margin-top: 12px;
        }
      }
    }
  }
}

.updatePlanLink {
  color: var(--primary-green, #00b840);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-decoration-line: underline;

  margin-left: 5px;
  cursor: pointer;
}
