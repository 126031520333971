.mainContainer {
  display: flex;
  max-width: 520px;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .container {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: auto;

    .headingWrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      max-width: 400px;
      margin: 0 auto 20px;

      p {
        color: #2e2e2e;
        margin: 0;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
      }
      .emailSpan {
        font-weight: 600;
      }
      img {
        width: 60px;
        height: 60px;
      }

      .janusLogo {
        justify-content: center;
        text-align: center;
        align-items: center;
        margin-bottom: 0;
      }
    }

    .forgotPassword {
      margin: 0;
      font-size: 30px;
      font-weight: 500;
      line-height: 27px;
    }

    .janusText {
      text-align: center;
    }

    .formData {
      justify-content: center;
      align-items: center;
      margin-bottom: 0;

      .emailInput {
        border-radius: 4px;

        &::placeholder {
          color: #2e2e2e;
        }
      }

      .submitBtn {
        margin-top: 10px;
        height: 45px;
        color: white;
        background-color: #00b840;
      }

      :global(.ant-form-item-required) {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #2e2e2e;
      }

      .loginWrraper {
        margin-top: 30px;
        .resendClickBtn {
          background-color: #00b840;
          width: 100%;
          color: #fff;
          height: 50px;
          line-height: 27px;
          font-weight: 500;
          font-size: 15px;
          div {
            margin-top: 5px;
          }
        }
      }
    }
    .resendText {
      color: #2e2e2e;
      text-align: center;
      display: inline-block;
      margin-top: 20px;
      .resendBtn {
        background: none;
        border: none;
        font-size: inherit;
      }
    }
    .outerLink {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      gap: 5px;
    }
    .aLink {
      cursor: pointer;
      color: #00b840;
    }
  }
}
