.userDetails {
  padding: 44px 48px;

  &Content {
    width: 90%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 200px;
    gap: 25px;

    & > :first-child {
      grid-column: 1 / 3;
    }
  }

  &Card {
    padding: 29px 26px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;

    &Row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &Item {
      display: flex;
      align-items: center;
      gap: 2px;
      margin-bottom: 15px;

      & > :first-child {
        color: var(--text-in-field, #2e2e2e);
        font-weight: 700;
        line-height: 25px;
      }

      & > :last-child {
        color: var(--Black, #000);
        font-weight: 500;
        line-height: 25px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &Title {
      color: var(--text-in-field, #2e2e2e);
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
      margin: 0 0 15px 0;
    }

    &Subtitle {
      color: var(--text-in-field, #2e2e2e);
      font-weight: 500;
      line-height: 25px;
      margin: 0 0 12px 0;
    }

    &Text {
      color: #858ba0;
      line-height: 25px;
      margin: 0;
      padding-bottom: 12px;
    }

    &Button {
      color: var(--primary-green, #00b840);
      font-weight: 500;
      line-height: 25px;
      cursor: pointer;
    }

    &Border {
      border-bottom: 1px solid #cecece;
    }
  }
}
