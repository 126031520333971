.header {
  width: 100%;
  height: 100%;
  padding: 0 16px 0 48px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #dde2e4;

  &Wrap {
    display: flex;
    align-items: center;
    gap: 17px;
  }

  &Title {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 40px;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &Button {
    padding: 10px 16px;

    font-weight: 600;
    line-height: 20px;
    color: var(--text-in-field, #2e2e2e);

    cursor: pointer;
    border-radius: 5px;
    background: var(--upgrade, #ffbe53);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.04);
  }

  &UserAvatar {
    width: 45px;
    height: 45px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;

    border-radius: 50%;
    background-color: #00b840;
  }
}
