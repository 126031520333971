.homePagePageContainer {
  min-height: 72vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 180px;
  max-width: 1310px;
  margin: 0 auto;

  @media only screen and (max-width: 1200px) {
    padding: 0 100px;
  }

  @media only screen and (max-width: 912px) {
    padding: 0 60px;
  }

  .homePageContainerCol {
    .homePageContainer {
      // margin-bottom: 250px;     min-height: 400px;
      // padding: 10px;

      h1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 28px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
      }

      .paraContainer {
        margin-bottom: 20px;
        p {
          font-family: "Inter";
          font-style: normal;
          margin: 0px;
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          color: #2e2e2e;
        }
      }

      .boxesContiner {
        .newProject {
          height: 175px;
          background: #244a32;
          border: 1px solid #c4c4c4;
          border-radius: 5px;
          cursor: pointer;
          &:hover {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }

          h4 {
            position: absolute;
            bottom: 0;
            left: 25px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 25px;
            color: #ffffff;
          }
        }

        .existingProject {
          box-sizing: border-box;
          min-height: 175px;
          background: #00b840;
          border: 1px solid #c4c4c4;
          border-radius: 5px;
          cursor: pointer;
          &:hover {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }

          h4 {
            position: absolute;
            bottom: 0;
            left: 25px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 25px;
            color: #ffffff;
            @media screen and (min-width: 760px) and (max-width: 970px) {
              font-size: 18px;
              max-width: 80%;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    height: max-content;
    margin-bottom: 50px;
  }
}
