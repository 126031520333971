.mainContainer {
  display: flex;
  max-width: 520px;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 10px;

  .container {
    width: 95%;
    margin: auto;

    .headingWrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      max-width: 400px;
      margin: auto;

      img {
        width: 60px;
        height: 60px;
      }

      .janusLogo {
        justify-content: center;
        text-align: center;
        align-items: center;
        margin-bottom: 0;
      }
    }

    .forgotPassword {
      margin: 20px 0;
      font-weight: 500;
      font-size: 30px;
    }

    .janusText {
      text-align: center;
      font-size: 16px;
      line-height: 25px;
      color: #2e2e2e;
      margin: 0 0 20px;
    }

    .formData {
      justify-content: center;
      align-items: center;
      margin-bottom: 0;

      .emailInput {
        max-width: 494px;
        font-size: 16px !important ;
        font-family: "Inter";
        border-radius: 4px;
        color: #2e2e2e;
      }

      .submitBtn {
        margin-top: 10px;
        height: 45px;
        color: white;
        background-color: #00b840;
      }

      :global(.ant-form-item-required) {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 19px;
        color: #000000;
      }

      .enterEmail {
        font-size: 40px;

        label {
          font-size: 16px;
          font-weight: 400;
        }
      }

      .loginWrraper {
        margin-top: 35px;
        .resendClickBtn {
          background-color: #00b840;
          width: 100%;
          color: #fff;
          height: 45px;
          font-weight: 500;
          font-size: 15px;
          line-height: 27px;
          div {
            margin-top: 5px;
          }

          &:hover {
            background-color: #11d756;
            color: #ffffff;
            outline: #00b840;
            border: #00b840;
          }
        }
      }
    }

    .outerLink {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      gap: 5px;
      font-size: 16px;
      p {
        margin: 7px 0;
      }
      .aLink {
        color: green;
      }
    }
  }
}

.bgWhite {
  background-color: #ffffff !important;
}
