.trialLogoWrraper {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  img {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.contentWrraper {
  margin: 30px;
  margin-top: 0px;

  .firstHead {
    font-family: "Inter";
    font-style: normal;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
  .secondHead {
    text-align: center;
    margin-top: 0px;
  }
}
