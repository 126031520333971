.mainWrraper {
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    font-size: 18px;
  }

  p {
    font-size: 16px;
  }
  span {
    color: blue;
  }
  .collapseWrraper {
    width: 80%;

    h1 {
      font-size: 30px;
    }
    p {
      font-size: 14px;
    }
    span {
      color: blue;
    }

    .plusIcon {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: rgba(75, 117, 139, 0.1);
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }
    :global(.ant-modal-content) {
      width: 900px;
      padding: 35px 24px;
    }
    :global(.ant-collapse-header) {
      background-color: #fff;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      :global(.ant-collapse-header-text) {
        color: #121212;
        font-size: 16px;
      }
    }
  }
}
