.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  padding: 24px 16px;
  background-color: #fff;
  height: 570px;
  border-radius: 8px;
  border: 1px solid #e4e4e7;
  width: 390px;
  margin-top: 20px;

  p {
    font-family: Inter, sans-serif;
    margin: 0;
  }

  .header {
    padding: 0px 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: #000;

    &Title {
      font-size: 20px;
      font-weight: bold;
    }

    &SubTitle {
      font-size: 14px;
      color: #2e2e2e;
      line-height: 125%;
    }

    &Price {
      font-size: 32px;
      font-weight: 600;
      font-weight: bold;
      margin-top: 16px;
    }
  }
}

.mostPopular {
  &Wrapper {
    border: 2px solid #00b840;
    position: relative;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }

  position: absolute;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;

  top: -26px;
  left: -2px;
  right: -2px;
  height: 26px;

  background-color: #00b840;
  border-radius: 8px 8px 0px 0px;

  p {
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 115%;
  }
}
