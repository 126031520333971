.card {
  width: 300px;
  background-color: #ffffff;
  padding: 65px 38px;
  cursor: pointer;

  &Count {
    color: #2e2e2e;
    text-align: center;
    font-size: 50px;
    font-weight: 500;
    line-height: 25px;

    margin: 0 0 27px 0;
  }

  &Type {
    color: #858ba0;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;

    margin: 0;
  }

  &LastUpdated {
    color: #2e2e2e;
    text-align: center;
    line-height: 25px;
    margin: 0;
  }
}
