@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
.projectsPagesNav {
  --border-color: #dde2e4;
  display: flex;
  height: 58px;
  background: white;
  border: 1px solid var(--border-color);
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 500px) {
    height: 60px;
  }
  .navigationItem {
    --width: calc(100% / 6);
    cursor: pointer;
    display: flex;
    box-sizing: border-box;
    height: 100%;
    width: calc(var(--width) + var(--width) / 9);
    margin-left: calc(var(--width) / 9 * -1);
    clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%, 10% 50%);
    background-color: var(--border-color);

    &RationalPage {
      --width: calc(100% / 4);
    }

    &TheoryOfChange {
      --width: calc(100% / 5);
    }

    .text {
      --padding: 12px;
      font-weight: 500;
      font-style: normal;
      font-family: "Inter";
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      padding: 0 15% 0 calc(var(--width) / 1.5 + var(--padding) + 5px);
      transform: translateX(-1px);

      clip-path: inherit;
      background: white;

      @media screen and (max-width: 1150px) {
        font-size: 12px;
      }
      @media screen and (max-width: 900px) {
        font-size: 10px;
        line-height: 12px;
      }
      @media screen and (max-width: 768px) {
        font-size: 9px;
        line-height: 9px;
      }
      @media screen and (max-width: 500px) {
        padding-left: 15%;
        font-size: 8px;
      }
    }

    .selectedText {
      background-color: #e4f8d2;
    }

    &:last-child {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 90% 100%, 0 100%, 10% 50%);
    }
  }
}

.dropdown {
  top: 69px;
  left: 0;
  z-index: 111111;
  position: absolute;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  background: white;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #cfd6dc;

  .selectedText {
    background-color: #e4f8d2 !important;
  }

  .text {
    font-weight: 500;
    font-style: normal;
    font-family: "Inter";
    font-size: 14px;
    line-height: 22px;
    display: flex;
    text-align: left;
    box-sizing: border-box;
    height: 100%;
    width: 100%;

    clip-path: inherit;
    background: white;

    @media screen and (max-width: 1150px) {
      font-size: 12px;
    }
    @media screen and (max-width: 900px) {
      font-size: 10px;
      line-height: 12px;
    }
    @media screen and (max-width: 768px) {
      font-size: 9px;
      line-height: 9px;
    }
    @media screen and (max-width: 500px) {
      font-size: 8px;
    }
  }
}

.navigationItemEvaluatePage {
  margin-left: calc(var(--width) / 9 * -0.85) !important;
}
