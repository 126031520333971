.login {
  &Wrap {
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &Title {
    color: #000;
    font-size: 30px;
    font-weight: 500;
    line-height: 27px;

    margin: 0 0 30px 0;
  }

  &Content {
    width: 100%;
    max-width: 450px;
    padding: 24px;
    margin-bottom: 40px;

    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 24px;

    border-radius: 4px;
    border: 1px solid #e0e0e0;
    background: var(--background-paper, #fff);

    &Title {
      color: #1e2134;
      font-feature-settings:
        "clig" off,
        "liga" off;

      font-size: 24px;
      font-weight: 600;
      line-height: 150%;

      margin: 0;
    }

    &Button {
      color: #fff;
      font-feature-settings:
        "clig" off,
        "liga" off;

      font-size: 15px;
      font-weight: 500;
      line-height: 26px;

      width: 90px;
      height: 40px;

      display: flex;
      align-items: center;
      justify-content: center;

      border: none;
      outline: none;

      border-radius: 4px;
      background: var(--primary-green, #00b840);

      cursor: pointer;
    }
  }

  &Form {
    :global(.ant-form-item-required) {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 19px;
      color: #000000;
    }
    .passwordInput {
      font-size: 16px;
      margin-bottom: 0;
    }

    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 14px;
  }

  :global(.ant-form-item-control) {
    font-size: 18px;
    font-family: "Inter";
  }

  :global(.ant-input) {
    font-size: 16px;
    font-family: "Inter";
  }
  :global(.ant-form-item-explain-error) {
    font-size: 15px;
  }
}
