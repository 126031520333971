* {
  font-family: "Inter";
}

.ant-input {
  height: 45px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  color: #2e2e2e;
}

.ant-input-password {
  height: 45px;
  padding: 0px 11px;
  height: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  color: #2e2e2e;
}

.ant-select-selector {
  width: 100%;
  height: 45px !important;
  padding: 0 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ant-input:placeholder {
  color: blue !important;
}

.ant-form-item-control {
  margin-bottom: -10px;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: unset;
  content: "";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* TODO: check for all code appearances */
/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/
