.saveQuitModal {
  .ant-modal-content {
    padding: 0;
    width: 570px;
  }

  .ant-modal-header {
    border-bottom: 1px solid #dde2e4;
    padding: 15px;
  }

  .ant-modal-body {
    padding: 0 15px;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 20px 20px;
    margin-top: 0px;

    .ant-btn {
      width: 250px;
      height: 44px;
      background-color: #e6e6e6;
      color: #808080;

      &:last-child {
        background-color: #00b840;
        color: #fff;
      }
    }

    .ant-btn:disabled {
      filter: grayscale(1);
      pointer-events: none;
    }
  }
}
