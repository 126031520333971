@import "@assets/styles/colors";

.customInputMarkdown {
  position: relative;
  width: 100%;

  font-size: 16px;
}

.customInputMarkdown {
  & > pre {
    white-space: pre-wrap;
  }
}

.customInput {
  display: flex;
  position: relative;
  width: 100%;

  font-size: 16px;

  :global(.ant-input-affix-wrapper) {
    &:global(.ant-input-password) {
      &.roundInput {
        // margin-bottom: 10px;
      }
    }
  }

  input,
  .roundInput {
    border-radius: 4px;
    font-weight: 400;
    margin: 1px;
    width: 100%;

    &::placeholder {
      font-style: italic;
    }

    &:hover {
      border: none;
      outline: none;
    }

    &:focus,
    &:active,
    &:visited {
      border: none;
      border-right-width: 1px;
      outline: none;
    }

    :global(.ant-form-item-explain-error) {
      color: blue;
    }
  }

  textarea {
    border-radius: 4px;
    margin: 1px;
    padding: 5px 10px;
    width: 100%;
    padding-top: 15px;

    &:hover {
      border: none;
      outline: none;
    }

    &:focus,
    &:active,
    &:visited {
      border: none;
      outline: none;
    }

    .roundInput {
      border-radius: 100px;
    }
  }

  .inputNumber {
    border: 0;
    width: 100%;

    input {
      height: 100%;
      position: absolute;
      top: 0;
      border: 1px solid #d9d9d9;
    }
  }

  :global(.ant-input-disabled) {
    background-color: white;
  }
}

.light {
  background-color: #f2f2f5;
  border: 1px solid #f2f2f5;
  color: #333;
}

.dark {
  background: var(--darkInputBg);
  border: 1px solid rgba(255, 242, 242, 0.38);

  :global(.ant-input) {
    background-color: rgba(255, 255, 255, 0);
    border-radius: 5px;
    color: var(--fontColorWithBg);
  }
}

.transparent {
  background-color: transparent;
  border: 1px solid;
  color: #a3a4a4;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

:global(.ant-form-item-control) {
  :global(.ant-form-item-explain-error) {
    color: default;
  }
}
