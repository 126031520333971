.footerContainer {
  // height: 58px;
  width: 100%;
  // background-color: #ffffff;
  position: inherit;
  bottom: 0;
  text-align: center;
  display: flex;
  font-size: 12px;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-height: 700px) {
    position: absolute;
    bottom: 0;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;

    line-height: 12px;
    text-align: center;
    color: #000000;
  }
}
