.modal {
  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 17px;
    position: relative;

    &::after {
      content: "";
      display: block;
      height: 1px;
      width: 720px;

      position: absolute;
      bottom: 0;
      left: -24px;

      background-color: #dde2e4;
    }

    &Title {
      color: #000;
      font-size: 18px;
      font-weight: 500;
      margin: 0;
    }
  }

  &Content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &Body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 34px;
    margin-bottom: 60px;
    gap: 18px;

    &Avatar {
      width: 114px;
      height: 114px;

      display: flex;
      align-items: center;
      justify-content: center;

      color: #fff;
      font-family: Inter;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px;

      border-radius: 50%;
      background-color: #00b840;
    }

    &Image {
      width: 53px;
      height: 53px;
      flex-shrink: 0;

      &Container {
        max-width: 114px;
        max-height: 114px;
        padding: 30px 24px 31px 37px;
        background-color: #f9f8f8;
        border-radius: 50%;
      }
    }

    &Bold {
      margin: 0;
      color: #2e2e2e;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
    }

    &Row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      width: 100%;
      border-bottom: 1.4px #cecece solid;

      &Text {
        padding-bottom: 15px;
        margin: 0;
        color: #2e2e2e;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    &Total {
      display: flex;
      justify-content: end;
      align-items: center;
      width: 100%;

      &Text {
        margin: 0;
        color: var(--Text-In-Field, #2e2e2e);
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        &Bold {
          margin-left: 30px;
          font-weight: 600;
        }
      }
    }

    &Counter {
      display: flex;
      align-items: center;
      height: 46px;
      margin-top: 5px;

      &Box {
        display: flex;
        padding: 10px 17px 11px 16px;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: 1px solid #e0e0e0;
      }

      &Text {
        margin: 0;
      }

      &Buttons {
        width: 30px;
        color: #2e2e2e;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        cursor: pointer;
      }
    }
  }

  &Footer {
    &Text {
      color: #2e2e2e;
      font-family: Inter;
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      text-align: center;
      margin: 0;

      &Box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 24px 0px;
        border-radius: 5px;
        border: 1px solid #e0e0e0;
        background: #f9f8f8;
      }
    }
  }

  &Button {
    width: fit-content;
    height: 45px;
    border-radius: 5px;
    padding: 14px 20px;

    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--primary-white, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: var(--primary-green, #00b840);
    cursor: pointer;

    &Cancel {
      color: #808080;
      background-color: #e6e6e6;
    }

    &Wrap {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-top: 32px;

      &Center {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -26px;
        padding-bottom: 29px;
      }
    }
  }
}
