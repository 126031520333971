.cardItem {
  background: #ffffff;
  border: 1px solid #00b84080;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 47px 19px;
  height: 250px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #4e4e4e;
    margin: 0;
    padding: 0px 12px;
    text-align: left;
    position: absolute;
    top: 22px;
  }

  .statusBadge {
    background: #ffb02f;
    //width: 100px;
    height: 24px;
    font-family: "Inter", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-left: -9px;
    //border: 1px solid #e0e0e0;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    color: #4e4e4e;
    margin-top: 10px;
    position: absolute;
    display: flex;
    top: 0px;
    white-space: nowrap;
  }

  h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1a1919;
    order: 0;
    max-width: 70%;
    margin: 14px auto 0;
  }

  // .withOutStatus {
  //   // padding-top: 22px;
  // }

  @media only screen and (max-width: 768px) {
    // p {
    //   right: 28px !important;
    // }

    // .statusBadge {
    //     right: 28px;
    //     width: 145px;

    //     p {
    //         left: 20;
    //     }
    // }
  }

  img {
    width: auto !important;
  }
}

.disableCards {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 47px 19px;
  height: 250px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  img {
    width: auto !important;
  }

  .statusBadge {
    background: rgba(255, 255, 255, 0.2);
    width: 100px;
    height: 24px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-left: -9px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4e4e4e;
    margin-top: 10px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    white-space: nowrap;
  }

  h4 {
    color: rgba(0, 0, 0, 0.5);
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    max-width: 95%;
    margin: 14px auto 0;
  }
}

.statusBadgeNew {
  width: 70px;
  height: 24px;
  background-color: #ffbe53;
  border-radius: 4px;
  position: absolute;
  top: 10px;

  color: var(--text-in-field, #2e2e2e);
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;

  cursor: pointer;
}
