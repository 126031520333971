.markdownWrapper {
}

.markdownCopilotReport {
  pre {
    white-space: pre-wrap;
  }

  strong {
    font-weight: unset !important;
  }

  li {
    text-align: justify;
  }

  & > div {
    display: flex;

    p {
      align-self: center;
      text-align: justify;
      line-height: 22px;
    }
  }

  & > * {
    font-family: "Inter", serif;
    font-size: 14px;
    margin: 5px 0;
  }
}
