.systemGeneratingResponse {
  color: #2e2e2e80;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;

  img {
    margin-right: 4px;
    margin-bottom: 10px;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
