.wrapper {
  display: flex;
  justify-content: center;
}

.mainLayout {
  font-family: Inter, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 10px 60px 10px;
  gap: 56px;
  max-width: 1222px;

  p {
    margin: 0;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    color: #18181b;
    font-size: 18px;

    p {
      color: #2e2e2e;
      line-height: 125%;
    }

    &Title {
      font-size: 32px;
      font-weight: 500;
      text-align: center;
      line-height: 115%;
    }
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &Text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    max-width: 1200px;
    align-self: start;

    &::before {
      color: #de3b3b;
    }

    &:nth-child(1)::before {
      content: "* ";
    }

    &:nth-child(2)::before {
      content: "** ";
    }

    &:nth-child(3)::before {
      content: "*** ";
    }
  }
}

.cardsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: end;
  gap: 16px;
}

.greenButton {
  border-radius: 5px;
  background-color: #00b840;
  width: 100%;
  border: none;
  color: #fff;
  height: 42px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.contactButton {
  border-radius: 5px;
  background: transparent;
  color: #00b840;
  border: 1px solid #00b840;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  height: 42px;
  cursor: pointer;
}

@mixin listStyle {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 16px;
  margin: 0px;
  padding: 0px;
  width: 100%;
  padding-left: 8px;
  padding-right: 16px;

  p {
    font-size: 16px;
    font-weight: 600;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      display: flex;
      gap: 16px;
      align-items: center;

      p {
        font-size: 14px;
        line-height: 125%;
        font-weight: 400;
      }

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.firstList {
  @include listStyle;

  li:nth-child(2) {
    p::after {
      content: " *";
      color: #de3b3b;
    }
  }
  li:nth-child(3) {
    p::after {
      content: " **";
      color: #de3b3b;
    }
  }
}

.secondList {
  @include listStyle;
}

.thirdList {
  @include listStyle;

  li:nth-child(4) {
    p::after {
      content: " ***";
      color: #de3b3b;
    }
  }
}
