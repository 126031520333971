.welcomeModal {
  width: 100%;
  max-width: 714px;
  z-index: 0;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  &Content {
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;

    padding: 42px 49px 80px 42px;
    margin-bottom: 40px;
  }

  &Title {
    color: #000;
    font-size: 25px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;

    margin: 0 0 27px 0;
  }

  &Desc {
    color: #2e2e2e;
    text-align: center;
    font-size: 16px;
    line-height: 25px;

    margin: 0 0 22px 0;
  }

  &Greetings {
    color: #2e2e2e;
    text-align: center;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: 25px;

    margin: 0;
  }

  &Button {
    width: 120px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    font-size: 15px;
    font-weight: 500;
    line-height: 27px;

    cursor: pointer;
    background-color: #00b840;
    border-radius: 5px;
  }
}
