.cardItem {
  background: #ffffff;
  border: 1px solid #00b84080;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 18px 19px;
  height: 196px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #4e4e4e;
    margin: 0;
    padding: 0px 12px;
    text-align: left;
    position: absolute;
    top: 22px;
  }

  .statusBadge {
    width: 100px;
    height: 24px;
    background: #ebfae2;
    border-radius: 4px;
    position: absolute;
    top: 10px;
    margin-left: -9px;

    p {
      font-family: "Inter";
      font-style: normal;
      margin: 0;
      padding: 0px 18px;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: #4f9c20;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0px;
      white-space: nowrap;
    }
  }

  h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1a1919;
    order: 0;
  }

  .withOutStatus {
    padding-top: 22px;
    text-align: left;
  }

  @media only screen and (max-width: 768px) {
    // p {
    //   right: 28px !important;
    // }

    // .statusBadge {
    //     right: 28px;
    //     width: 145px;

    //     p {
    //         left: 20;
    //     }
    // }
  }
}

.disableCards {
  // box-sizing: border-box;
  // background: rgba(255, 255, 255, 0.2);
  // border: 1px solid #E0E0E0;
  // border-radius: 5px;
  // padding: 20px;
  // height: 196px;
  // display: flex;
  // flex-direction: column;
  // margin-bottom: 20px;
  // justify-content: center;

  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 18px 19px;
  height: 196px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    background: rgba(255, 255, 255, 0.2);
    width: 100px;
    height: 24px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-left: -9px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4e4e4e;
    margin-top: 10px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    white-space: nowrap;
  }

  h4 {
    color: rgba(0, 0, 0, 0.5);
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
  }
}

.statusBadgeNew {
  width: 100px;
  height: 24px;
  background: #ebfae2;
  border-radius: 4px;
  position: absolute;
  top: 10px;
  margin-left: -9px;

  p {
    font-family: "Inter";
    font-style: normal;
    margin: 0;
    padding: 0px 18px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #4f9c20;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    white-space: nowrap;
  }
}
