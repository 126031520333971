.top {
  background-color: #f6f6f6;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 202px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.title {
  color: #000000;

  font-size: 22px;
  font-weight: 600;
  line-height: 24.2px;
  text-align: center;
}

.contentWrapper {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;

  color: #2e2e2e;
  padding: 0px 20px 0 27px;

  ul {
    padding-left: 20px;

    li {
      margin-top: 20px;
    }
  }

  &Signature {
    text-align: center;
  }
}

.footer {
  display: flex;
  justify-content: center;
  height: 70px;
  padding-top: 5px;

  &Content {
    width: 418px;
  }
}

.gotItBtn {
  cursor: pointer;
  border: none;
  text-align: center;
  padding: 0 25px;
  height: 44px;
  background-color: #00b840;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
}

.gotItBtnShallow {
  margin-left: 17px;
  color: #00b840;
  background-color: white;
  border-color: #00b840;
  border-width: 1px;
  border-style: solid;
}
