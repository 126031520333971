.contextBaselineDiv {
  background-color: #00792a;
  // background: linear-gradient(0deg, #ffffff, #ffffff),

  min-height: 80px;
  display: grid;

  grid-template-columns: 250px 1fr 300px 200px;

  // border: 1px solid #eaebf0;
  padding: 0px 0px;

  :global(.header-item) {
    padding: 0px 20px;
    margin: 0;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:not(:first-child)::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      z-index: 5;
      height: 60%;
      width: 1px;
      background-color: #cecece;
    }

    p {
      margin: 0;
      margin-bottom: 5px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.1px;
      color: #ffffff;
      white-space: nowrap;
      overflow: hidden;
      flex-wrap: wrap;
      word-break: break-all;
      opacity: 80%;
    }

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
    }

    img {
      position: absolute;
      top: 5px;
      right: 16px;
    }
  }

  :global(.ant-col:nth-child(1)) {
    border-right: 1px solid #eaebf0;
  }

  :global(.ant-col:nth-child(2)) {
    border-right: 1px solid #eaebf0;
  }
  :global(.ant-col:nth-child(3)) {
    border-right: 1px solid #eaebf0;
  }
}

.tooltip {
  width: 300px;
  height: fit-content;

  padding: 11px 22px 14px 22px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  position: relative;
  z-index: 1;

  &Title {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 4px;
  }

  &Desc {
    color: #2e2e2e;
    font-size: 14px;
    line-height: 21px;
  }

  &Arrow {
    width: 14.825px;
    height: 14.855px;
    transform: rotate(45.148deg);
    flex-shrink: 0;
    background-color: #ffffff;
    position: absolute;
    left: 50%;
    z-index: 2;
    margin-left: 8px;
    top: 13px;
  }

  &Wrap {
    width: fit-content;
    height: fit-content;
    padding: 20px;
    position: absolute;
    right: -124px;
    top: 26px;
    z-index: 3;
  }
}

.defaultToolBanner {
  &Text {
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    color: #ffffff;
    flex-direction: row !important;
  }
}
