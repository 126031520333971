.popup {
  position: absolute;
  top: 50px;
  right: calc(50% - 135px);
  padding: 10px;

  & > div {
    padding: 5px;
    border-radius: 5px;
    width: 250px;
    background-color: #ffffff;
    border: 1px solid var(--neutral-600, #eaebf0);
  }
}
