.dashboard {
  padding: 62px 72px;

  &Cards {
    width: fit-content;
    margin: 0 auto 40px auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
  }

  &Table {
    display: grid;

    row-gap: 28px;
    grid-template-columns: 1fr 1fr 2fr;

    padding: 10px;

    &HeadItem {
      color: var(--text-in-field, #2e2e2e);
      font-size: 18px;
      line-height: 20px;

      padding-bottom: 12px;
      border-bottom: 1px solid #cecece;
    }

    &BodyItem {
      color: var(--text-in-field, #2e2e2e);
      font-size: 16px;
    }

    &Wrap {
      &Title {
        color: #000;

        margin: 0 0 30px 0;

        font-size: 20px;
        font-weight: 500;
        line-height: 27px;
      }
    }
  }

  &Alerts {
    &Wrap {
      &Title {
        color: #000;
        margin: 0 0 30px 0;

        font-size: 20px;
        font-weight: 500;
        line-height: 27px;
      }

      &SubTitle {
        color: var(--text-in-field, #2e2e2e);
        font-size: 18px;
        line-height: 20px;
        padding-bottom: 8px;
        border-bottom: 1px solid #cecece;
        margin-bottom: 20px;
      }
    }

    &Table {
      display: grid;
      grid-template-columns: 1fr;
      gap: 12px;

      &Item {
        color: var(--text-in-field, #2e2e2e);
        font-size: 18px;
        margin: 0;
      }
    }
  }
}
