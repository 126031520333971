.formWrapper {
  font-size: 16px;

  .form {
    display: flex;
    flex-direction: column;
    gap: 18px;

    .labelContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .reportButton {
      cursor: pointer;
      border: none;
      color: #00b840;
      background: none;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 27px;
      width: fit-content;
      height: 40px;
      text-decoration: underline;
    }

    .label {
      font-size: 20px;
      font-weight: 500;
      line-height: 140%;
      text-transform: capitalize;
      letter-spacing: -0.12px;
    }

    .viewOutputButton {
      color: #00b840;
      cursor: pointer;
      text-decoration: underline;
    }

    .viewOutputButtonDisabled {
      color: #b6b6b6;
      cursor: not-allowed;
      text-decoration: underline;
    }

    .textareaWrapper {
      padding: 28px 24px;
      border: 1px solid rgb(224, 224, 224);
      min-height: 51vh;
      max-height: 51vh;
      background-color: #fff;
      margin-bottom: 15px;
      overflow-y: auto;

      img {
        cursor: pointer;
        margin-top: 10px;
      }
    }

    .textarea {
      padding: 0px !important;
      line-height: 22px;
      border-width: 0px !important;

      &Submitting {
        color: #2e2e2e;
        font-style: italic;
      }

      &:disabled {
        color: #2e2e2e;
      }

      &:focus {
        outline: none !important;
        border: none !important;
        box-shadow: none;
      }

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
        font-size: 18px;
        font-style: italic;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}
