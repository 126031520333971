.welcomeModal {
  width: 100%;
  max-width: 900px;
  height: 512px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  @media screen and (max-width: 1320px) {
    top: 50%;
  }

  &Content {
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;

    padding: 25px;
    margin-bottom: 20px;

    @media screen and (max-width: 1320px) {
      margin-bottom: 20px;
      padding: 22px 29px 20px 22px;
    }
  }

  &Title {
    color: #000;
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    line-height: 35px;

    @media screen and (max-width: 1320px) {
      font-size: 18px;
      margin: 0 0 15px 0;
    }

    margin: 0 0 27px 0;
  }

  &Desc {
    color: #2e2e2e;
    text-align: left;
    font-size: 16px;
    line-height: 25px;

    @media screen and (max-width: 1320px) {
      font-size: 14px;
      margin: 0 0 18px 0;
    }

    margin: 0 0 22px 0;
  }

  &Greetings {
    color: var(--text-in-field, #2e2e2e);
    text-align: center;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: 25px;

    margin: 0;
  }

  &Button {
    width: 120px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    font-size: 15px;
    font-weight: 500;
    line-height: 27px;

    cursor: pointer;
    background-color: #00b840;
    border-radius: 5px;
  }
}
