.table {
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;

  & thead {
    border-bottom: 1px solid red !important;
    background-color: #ffffff !important;
    height: 45px !important;

    & th {
      font-weight: 400 !important;
    }
  }

  &FileIcon {
    width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    background-color: #f6f6f6;

    & svg {
      width: 14px;
      height: 14px;

      padding-left: 1px;
    }
  }
}

:global(.iWmiAp .ant-table-thead) {
  background-color: #ffffff;
}

:global(.ant-table-thead > tr > th) {
  height: 45px !important;
}

:global(.ant-table-tbody > tr:hover > td) {
  cursor: pointer;
}

.eyeIcon {
  & svg {
    width: 18px;
    height: 18px;
  }
}
