.mainContainer {
  min-height: 87vh;
  background-color: #f7f7f7;
  .contextBaselineDiv {
    background-color: #ffffff;
    min-height: 74px;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;

    .pTageOne {
      font-family: "Inter";
      font-style: normal;
      padding-left: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #2e2e2e;
      margin-left: -10px;
    }

    .pTageTwo {
      margin-left: 10px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #2e2e2e;
      margin-left: 0px;
    }

    .pTageThree {
      font-style: Medium;
      margin-right: 35px;
      font-family: "Inter";
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #2e2e2e;
    }
  }

  .barContentContainer {
    display: flex;

    .layoutDiv {
      display: flex;

      .siderStyle {
        background-color: #ffffff;
        border: 1px solid #dde2e4;
        width: 289px;
        height: 100%;

        .sideInnerDiv {
          max-width: 100%;
          .importantDiv {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #dde2e4;
            padding: 0 20px;
            padding-bottom: 10px;

            .pTagFour {
              font-size: 20px;
              font-weight: 600;
              padding-left: 10px;
              margin-top: 30px;
            }
            .btnClass {
              background-color: #ffffff;
              height: 40px;
              margin-top: 10px;
              text-align: center;
              align-items: center;
              width: 40px;
              border-radius: 50px;
              cursor: pointer;
              border: 1px solid #e0e0e0;
            }
            .closeIconImg {
              margin-top: 5px;
            }
          }

          .pTageGroup {
            max-width: 90%;
            margin: auto;
            padding: 0 20px;
            text-align: left;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            letter-spacing: -0.006em;
            color: #000000;
          }
        }
      }
    }

    .contentContainer {
      width: 100%;
      display: flex;
      flex-direction: column;

      .innerContentContainer {
        width: 90%;
        margin: auto;
        padding: 9px 0px;
        margin-top: 2px;

        .headingContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .viewOutputButton {
            color: #00b840;
            cursor: pointer;
            text-decoration: underline;
          }

          .viewOutputButtonDisabled {
            color: #b6b6b6;
            cursor: not-allowed;
            text-decoration: underline;
          }
        }

        h1 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 140%;
          letter-spacing: -0.006em;
          text-transform: capitalize;
          color: #000000;
        }

        .dataContentBox {
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 5px;
          min-height: 400px;
          padding: 40px 100px 80px 40px;

          .formData {
            .questionOne {
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 94px;
              flex-wrap: wrap;
              :global(.ant-form-item-control) {
                width: 100% !important;
              }

              @media only screen and (max-width: 1200px) {
                grid-template-columns: 1fr;
                grid-template-rows: 1fr 1fr;
                gap: 0;
              }
            }

            :global(.ant-input) {
              min-height: 150px;
              padding: 18px;
              line-height: 1;
              background: #ffffff;
              border: 1px solid #e0e0e0;
              border-radius: 5px;
              margin-bottom: 25px;
            }

            :global(.ant-form-item-label > label) {
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 17px;
              line-height: 22px;
              color: #000000;
            }

            :global(.ant-form-item-explain-error) {
              margin-bottom: 25px;
            }
          }
          @media only screen and (max-width: 1220px) {
            .questionOne {
              flex-direction: column;
            }
          }
        }

        .footerButtonsDiv {
          margin: 50px 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .nextButton {
            &:disabled {
              background-color: gray;
            }
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            // width: 92px;
            height: 40px;
            font-size: 20px;
            line-height: 27px;
            border: none;
            color: #ffffff;
            background: #00b840;
            border-radius: 5px;
            padding: 6px 20px;
          }

          .btnDiv {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .twoBtnDiv {
              display: flex;
              justify-content: center;
              gap: 11px;
              .goBtn {
                // height: 50px;
                // width: 80px;
                color: #2e2e2e;
                background: #ececf1;
                border-radius: 5px;
                cursor: pointer;
                border: none;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 5px;
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 27px;
                width: 116px;
                height: 40px;
                img {
                  margin-top: 1px;
                }
              }
              .saveBtn {
                width: 116px;
                height: 40px;
                color: #2e2e2e;
                white-space: nowrap;
                background: #ececf1;
                border-radius: 5px;
                cursor: pointer;
                border: none;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-weight: 500;
                font-size: 20px;
                line-height: 27px;
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                line-height: 27px;
              }
            }
            @media only screen and (max-width: 425px) {
              .twoBtnDiv {
                margin-bottom: 0;
                .goBtn {
                  height: 40px;
                  width: 80px;
                }
                .saveBtn {
                  height: 40px;
                  width: 90px;
                }
              }
            }
          }
          @media only screen and (max-width: 425px) {
            .nextButtonDiv {
              .nextButton {
                &:disabled {
                  background-color: gray;
                }
                height: 40px;
                width: 114px;
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;

      .layoutDiv {
        .siderStyle {
          width: 90%;
          margin: auto;
          margin-top: 25px;
        }
      }
    }
  }
  @media only screen and (max-width: 425px) {
    .contextBaselineDiv {
      flex-direction: column-reverse;

      .pTageThree {
        margin-bottom: 0px;
      }
    }
  }
}

.emailInput {
  height: 50px !important;
  min-height: 50px !important;
  max-height: 50px !important;
  // width: 50% !important;
  font-family: "Inter";
  width: 100%;
  font-size: 16px !important;
  border: 1px solid #bcbcbc !important;
  @media only screen and (max-width: 1645px) {
    width: 450px !important;
  }
}

.fontSizeInput {
  font-size: 16px !important;
  font-family: "Inter";
  border: 1px solid #bcbcbc !important;
  height: 450px !important;
  min-height: 450px !important;

  &::placeholder {
    font-style: normal !important;
    color: rgba(0, 0, 0, 0.5) !important;
    font-family: Inter, sans-serif !important;
    line-height: 130%;
  }
}

:global(.select) > div {
  height: 52px !important;
}

.form-item > :global(.ant-form-item) {
  margin-bottom: 0 !important;
}
