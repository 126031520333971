.sidebar {
  width: 100%;
  height: 100%;
  padding: 24px 28px;

  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  border-right: 1px solid #dde2e4;

  &Header {
    margin-bottom: 24px;
  }

  &Logo {
    width: 40px;
    height: 42px;

    &Text {
      position: relative;
      top: -8px;

      color: #00b840;
      font-size: 10px;
      font-weight: 600;
      line-height: 20px;
      text-transform: uppercase;
    }
  }

  &Navigation {
    margin: 0 0 4px 0;

    display: flex;
    flex-direction: column;
    gap: 4px;

    list-style: none;
    padding: 0;

    &Item {
      display: flex;
      align-items: center;
      gap: 12px;

      padding: 12px 0;
      cursor: pointer;

      & > p {
        color: #2e2e2e;
        font-size: 15px;
        font-weight: 600;
        line-height: 22px;
        margin: 0;
      }

      &:hover {
        & > p {
          color: #00b840;
        }
      }
    }
  }

  &Footer {
    margin-top: auto;

    &Button {
      width: fit-content;
      padding: 16px 24px;
      margin-top: auto;
      border-radius: 5px;
      text-align: center;

      color: var(--primary-green, #00b840);
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      border: 1px solid var(--primary-green, #00b840);

      cursor: pointer;

      &Disabled {
        color: #808080;
        background: #e6e6e6;
        border: none;
      }
    }

    &Desc {
      color: #2e2e2e;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.1px;

      margin: 0 0 12px 0;
    }
  }
}
