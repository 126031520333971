.resource {
  padding: 40px 30px;

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 34px;

    &Nav {
      font-weight: 500;
      font-size: 18px;
    }

    &Button {
      width: fit-content;
      height: 40px;
      border-radius: 5px;
      padding: 14px 20px;

      // TODO: temporary
      opacity: 0.5;

      border: none;
      display: flex;
      align-items: center;
      gap: 9px;

      color: var(--primary-white, #fff);
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      background: var(--primary-green, #00b840);
      cursor: pointer;
    }
  }

  &Tab {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: white;
    border: 1px solid #cecece;

    width: 100%;
    min-width: 300px;
    max-width: 325px;

    &Text {
      margin-left: 10px;
      font-size: 16px;
      color: #333;
      width: 100%;
      cursor: pointer;
    }

    &Icon {
      cursor: pointer;
    }

    &Dots {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  &File {
    width: 240px;
    height: 240px;

    position: relative;

    border-radius: 8px;
    background-color: white;
    border: 1px solid #cecece;

    &Icon {
      position: absolute;

      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &Zip {
      position: absolute;

      color: #ffffff;
      font-size: 16px;

      top: 43%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &Dots {
      position: absolute;
      top: 5px;
      right: 10px;

      cursor: pointer;
    }

    &Text {
      width: 100%;
      padding: 0 10px;

      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);

      text-align: center;
    }
  }

  &Country {
    display: flex;
    align-items: center;
    gap: 40px;

    padding-bottom: 34px;
    margin-bottom: 40px;
    border-bottom: 1px solid #cecece;
  }

  &Files {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  &Switcher {
    width: 110xp;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 8px;

    cursor: pointer;

    background-color: #ffffff;

    & div {
      width: 40px;
      height: 40px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 8px 0 0 8px;
    }
  }
}

.modalSelectWrap {
  & > select {
    height: 40px !important;
  }
}

:global(.ant-select-selector) {
  height: 40px !important;
}

:global(.ant-select-arrow) {
  padding-top: 10px !important;
}

:global(.ant-select-selection-placeholder) {
  color: #4d4d4d !important;
}

.popup {
  width: 150px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &Item {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    & svg {
      width: 20px !important;
      height: 20px !important;
    }

    & > p {
      margin: 0;
    }
  }
}
