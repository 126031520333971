.mainLoginWrraper {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  //flex-direction: column;
  //gap: 50px;
  // padding: 12px;
  justify-content: center;
  height: 100vh;

  .maintenance {
    font-family: "Inter", serif;
    background: #ffb1a9;
    width: 900px;
    border-radius: 5px;
    padding: 0 40px;

    &Heading {
      //position: relative;
    }

    h3 {
      font-size: 18px;
      margin-bottom: -10px;
      margin-top: 0px;
    }

    p {
      font-size: 16px;
    }

    img {
      width: 18px;
      height: 18px;
      position: relative;
      top: 24px;
      left: -23px;
    }
  }

  .welcomeWrraper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;

    img {
      width: 60px;
      height: 60px;
      margin-bottom: 20px;
    }

    h2 {
      font-family: "Inter";
      font-style: normal;
      margin: 0px;
      font-weight: 500;
      font-size: 30px;
      color: #000000;
    }
  }

  .emailWrraper {
    margin-top: 20px;

    p {
      margin-bottom: 8px;
    }
  }

  .passwordWrraper {
    margin-top: 20px;

    p {
      margin-bottom: 8px;
      font-family: "Inter";
    }
  }

  .loginWrraper {
    p {
      margin-bottom: 30px;
      margin-top: 14px;
      color: #00b840;
      font-weight: 400;
      font-size: 18px;
      font-family: "Inter";
      cursor: pointer;
      margin-left: 10px;
    }

    .loginBtn {
      background-color: #00b840;
      width: 100%;
      color: #fff;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 500;

      div {
        margin-top: 5px;
      }

      &:hover {
        background-color: #11d756;
        color: #ffffff;
        outline: #00b840;
        border: #00b840;
      }
    }
  }

  .signupWrraper {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-weight: 400;
      font-size: 18px;
      font-family: "Inter";
      color: #000000;
    }

    span {
      color: #00b840;
      margin-left: 4px;
      font-size: 18px;
      font-family: "Inter";
      cursor: pointer;
    }
  }

  .loginForm {
    :global(.ant-form-item-required) {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 19px;
      color: #000000;
    }
    .passwordInput {
      font-size: 16px;
    }
  }

  :global(.ant-form-item-control) {
    margin-bottom: -10px;
    font-size: 18px;
    font-family: "Inter";
  }

  :global(.ant-input) {
    font-size: 16px;
    font-family: "Inter";
  }
  :global(.ant-form-item-explain-error) {
    font-size: 15px;
  }
}

.mainLoginWrraperText {
  @media (max-height: 870px) {
    position: relative;
    top: 120px;
  }
}
