.modal {
  border-radius: 8px;
  background-color: #ffffff;

  &Header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: calc(100% + 48px);
      height: 1px;

      position: absolute;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);

      background-color: #dde2e4;
    }
  }

  &Title {
    color: var(--Black, #000);
    font-weight: 600;
    font-size: 14px;
  }

  &ContentWrap {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto repeat(4, 85px);

    border: 1px solid #e6e9f5;
    border-bottom: none;

    margin-bottom: 10px;
  }

  &ContentItem {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 12px;

    color: var(--black, #000);
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    border-bottom: 1px solid #e6e9f5;
    border-right: 1px solid #e6e9f5;

    &Head {
      padding: 28px;
      border-bottom: 1px solid #e6e9f5;
      border-right: 1px solid #e6e9f5;

      display: flex;
      flex-direction: column;
      justify-content: start;

      &Row {
        border-bottom: 1px solid #e6e9f5;
        border-right: 1px solid #e6e9f5;
        padding: 20px 32px;

        display: flex;
        align-items: center;

        color: var(--black, #000);
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;

        &Asterisk {
          &::after {
            content: "*";
            color: red;
          }
        }
      }

      &Title {
        color: #000;
        font-family: Inter;
        font-size: 25px;
        font-weight: 600;
        margin: 0;

        &Wrap {
          display: flex;
          gap: 16px;
          align-items: center;
          margin-bottom: 12px;
        }

        &Button {
          margin: 0;
          padding: 10px 20px;
          border-radius: 20px;
          background: var(--primary-green, #00b840);

          color: #fff;
          font-family: Inter;
          font-size: 10px;
          font-weight: 500;

          cursor: pointer;
        }
      }

      &Desc {
        color: #858ba0;
        font-family: Inter;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        margin: 0 0 10px;
      }

      &Price {
        color: #000;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0 0 8px;
      }

      &Button {
        padding: 16px 24px;
        margin-top: auto;
        border-radius: 5px;
        text-align: center;

        color: var(--primary-green, #00b840);
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        border: 1px solid var(--primary-green, #00b840);

        cursor: pointer;

        &Disabled {
          color: #808080;
          background: #e6e6e6;
          border: none;
        }
      }
    }
  }

  &Footer {
    padding-left: 33px;

    &Item {
      color: #858ba0;
      font-family: Inter;
      font-size: 15px;
      line-height: 20px;

      margin: 0 0 10px;

      &::before {
        content: "*  ";
        color: red;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  &CloseIcon {
    position: absolute;
    top: 12px;
    right: 20px;
    cursor: pointer;
  }
}

.signUpForm {
  width: 95%;

  padding: 22px 0 15px 0;
}

.input {
  width: 100%;
  font-size: 16px !important ;
  border-radius: 4px;
  color: #2e2e2e;

  &Label {
    display: block;
    margin-bottom: 8px;

    color: #000;
    font-size: 16px;

    & > span {
      color: #de3b3b;
      font-family: Inter;
      font-size: 16px;
    }
  }

  &Texarea {
    width: 100%;
    height: 165px !important;
    padding: 16px !important;
    resize: none !important;
    line-height: 100% !important;
    margin-bottom: 20px !important;

    &::placeholder {
      line-height: 100%;
      font-style: normal !important;
    }
  }
}

.buttonWrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.button {
  width: 125px;
  height: 45px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  outline: none;

  border-radius: 5px;
  background: var(--primary-green, #00b840);

  color: #fff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  &Grey {
    background-color: #e6e6e6;
    color: #808080;
  }

  &:hover {
    background-color: #11d756;
    color: #ffffff;
    outline: #00b840;
    border: #00b840;
  }
}
