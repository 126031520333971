.modal {
  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &Title {
      color: var(--Black, #000);
      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
      max-width: 490px;
      margin: 0;
    }
  }

  &Banner {
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;
    padding: 19px;
    margin-bottom: 15px;

    display: flex;
    flex-direction: column;
    gap: 12px;

    &TypeToChange {
      color: var(--primary-green, #00b840);
      margin: 0;
    }

    &CurrentType {
      color: var(--text-in-field, #2e2e2e);
      margin: 0;

      &::after {
        content: "(Current plan)";
        padding-left: 5px;
        font-weight: 300;
        padding-top: 3px;
      }
    }
  }

  &Button {
    width: 40%;
    height: 45px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--primary-white, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: var(--primary-green, #00b840);
    cursor: pointer;

    &Disabled {
      color: #808080;
      background-color: #e6e6e6;
    }

    &Wrap {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-top: 28px;
    }
  }
}
