.homePagePageContainer {
  width: 100%;
  min-height: 72vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .homePageContainerCol {
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .homePageContainer {
      margin-bottom: 120px;
      //  min-height: 400px;
      padding: 10px;

      h1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 28px;
        align-items: center;
        text-align: center;
        color: #000000;
      }

      .paraContainer {
        margin-bottom: 20px;
        p {
          font-family: "Inter";
          font-style: normal;
          margin: 0px;
          font-weight: 400;
          font-size: 16px;
          line-height: 25px;
          color: #2e2e2e;
        }
      }

      .boxesContiner {
        padding-top: 20px;
        .cardItem {
          background: #ffffff;
          border: 1px solid #e0e0e0;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          padding: 20px;
          height: 196px;
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;

          p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            color: #4e4e4e;
            margin: 0;
            padding: 0px 12px;
            text-align: flex-start;
          }

          .statusBadge {
            width: 92px;
            height: 24px;
            background: #ebfae2;
            border-radius: 4px;
            // display: flex;
            // align-self: flex-end;

            p {
              font-family: "Inter";
              font-style: normal;
              margin: 0;
              padding: 0px 12px;
              font-weight: 500;
              font-size: 12px;
              line-height: 24px;
              color: #4f9c20;
            }
          }

          h4 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #1a1919;
            order: 0;
          }

          .withOutStatus {
            padding-top: 22px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    height: max-content;
    margin-bottom: 50px;

    .homePageContainerCol {
      width: 80%;
    }
  }
  @media only screen and (max-width: 992px) {
    .homePageContainerCol {
      width: 80%;
    }
  }
}
.btnDiv {
  margin: 0;
  width: 100%;

  .twoBtnDiv {
    // display: flex;
    padding-inline: 28px;
    // margin-top: 60px;
    width: 100%;
    border-top: 1px solid #e0e0e0;
    background-color: #ffffff;
    height: 90px;
    position: inherit;
    // bottom: 0px;
    // justify-content: space-between;
    @media only screen and (min-height: 840px) {
      position: absolute;
      bottom: 0;
    }

    .goBackBtnDiv {
      padding-top: 22px;
      width: 100%;
      margin-left: 15px;

      .goBtn {
        height: 50px;
        width: 134px;
        color: #2e2e2e;
        background-color: #e6e6e6;
        border-radius: 5px;
        cursor: pointer;
        border: none;
        display: flex;
        font-size: 20px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5px;

        img {
          margin-top: 1px;
        }
      }
    }
    .saveBtn {
      color: #2e2e2e;
      background-color: #e6e6e6;
      height: 50px;
      width: 134px;
      border: none;
      font-size: 20px;
      white-space: nowrap;
      cursor: pointer;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }
  @media only screen and (max-width: 425px) {
    .twoBtnDiv {
      padding-inline: 20px;
      margin-bottom: 0;
      .goBtn {
        height: 40px;
        width: 114px;
      }
      .saveBtn {
        height: 40px;
        width: 114px;
      }
    }
  }
}
