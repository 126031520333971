.homePagePageContainer {
  width: 100%;
  margin-top: 40px;
  min-height: 100vh;
  background-color: #f7f7f7;

  padding: 0 188px;

  @media only screen and (max-width: 1200px) {
    padding: 0 100px;
  }

  @media only screen and (max-width: 912px) {
    padding: 0 60px;
  }

  @media only screen and (min-width: 700px) {
    .responsive {
      padding-left: 148px;
      padding-right: 188px;
    }
  }

  .responsiveTable {
    box-shadow:
      0px 1px 3px rgba(0, 0, 0, 0.1),
      0px 2px 1px rgba(0, 0, 0, 0.06),
      0px 1px 1px rgba(0, 0, 0, 0.08);
    @media only screen and (min-width: 1300px) {
      width: 94%;
    }
  }

  .projectHeading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
  }
  .firstdivaragraph {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    padding-top: 0px;
    margin-top: 23px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 40px;
    // padding: 23px 10px 10px 0px;
    // width:100%;
    // width:
    // padding-top: 23px;
  }
  .secondParahGraph {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    padding-top: 0px;
    margin-top: 42px;
    color: #000000;
    margin-bottom: 19px;
  }
  .flexWrapper {
    display: flex;
    gap: 8px;
    margin-right: 26px;
  }
  //  .scrollClass{
  //   width: 100%;
  //   overflow-x: scroll;
  //  }
  .imgClass {
    cursor: pointer;
  }
  .thirdPara {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    color: #000000;
    margin-top: 42px;
    padding-top: 0px;
  }
  .lastPara {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    color: #000000;
    margin-top: 164px;
    padding-top: 0px;
  }
  .completeClass {
    background: #c7fec6;
    border-radius: 32px;
    height: 23px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    // line-height: 24px;
    width: 80px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #039700;
  }
  .inprocessClass {
    background: #ffebc5;
    border-radius: 32px;
    height: 23px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    // line-height: 24px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 89px;
    color: #ea7e00;
  }
}
.tablepara {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  max-width: 600px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.cretionPara {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2e2e2e;
}
