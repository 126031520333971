.topHeaderBar {
  min-height: auto;
  padding-bottom: 10px;
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  border-bottom: 1px solid #dde2e4;
  z-index: 10;

  .headerContainer {
    min-height: 50px;
    background-color: #ffffff;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headerMenuContainer {
      display: inline;
      float: left;
      margin-top: 4px;

      .welcomeText {
        width: fit-content;
        img {
          height: 48px;
          width: 48px;
          margin-right: 20px;
          margin-left: 38px;
        }
      }
    }

    .menuHeader {
      padding-left: 10px;
      display: inline;
      float: left;
      border: none;

      :global(.ant-menu-item) {
        width: auto;
        margin: 5px 5px;
        padding: 0px 16px;
        font-size: 15px !important;
        font-weight: 500;
        color: #2e2e2e;

        &::after {
          border: none;
        }
      }

      :global(.ant-menu-item-selected) {
        font-family: "Inter";
        font-size: 15px !important;
        font-weight: 600;
        text-align: left;
        color: #000000;
        padding: 0px 16px;
        background: #f5f5f5 !important;
        border-radius: 5px;
      }
    }

    .rightMenuHeader {
      display: flex;
      float: right;
      align-items: center;
      gap: 20px;
      list-style: none;
      margin-bottom: 0;
      margin-top: 0;
      padding-left: 0;

      .userProfileDropDownContainer {
        margin-right: 24px;

        .userProfileDropDownWrapper {
          display: flex;
          flex-direction: row;
          gap: 24px;
          align-items: center;

          @media screen and (max-width: 500px) {
            gap: 16px;
          }

          .billingInfoButton {
            font-family: "Inter";
            background: #ffbe53;
            color: #000;
            padding: 10px 16px;
            border: none;
            border-radius: 5px;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;

            @media screen and (max-width: 500px) {
              padding: 8px 14px;
            }
          }
          .billingInfo {
            height: 40px;
            background: #ffcd7c;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
            border-radius: 5px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 20px;
            color: #2e2e2e;
          }

          .profileUserNameText {
            font-size: 15px;
            color: #2e2e2e;
            font-weight: 500;
            @media screen and (max-width: 500px) {
              font-size: 16px;
            }
          }

          .profileUserNameText:hover {
            color: #000000;
          }

          .spaceUserProfile {
            gap: 5px !important;
            @media screen and (max-width: 500px) {
              gap: 2px !important;
            }
          }
        }
      }
    }

    .emailBannerWrap {
      padding: 7px;
      border-radius: 5px;
      border: 1px solid var(--neutral-600, #eaebf0);
      background: var(--base-white, #fff);
      display: flex;
      align-items: center;
      gap: 5px;
      position: relative;
    }

    .emailBannerIconWrap {
      //width: 40px;
      //height: 40px;
      display: flex;
      //justify-content: center;
      //align-items: center;
      //border-radius: 50%;
      //border: 1px solid var(--neutral-600, #eaebf0);
    }

    .emailHeadingText {
      color: #2e2e2e;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 183.333% */
      letter-spacing: -0.1px;
      margin: 0;
    }

    .emailText {
      color: var(--text-in-field, #2e2e2e);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.1px;
    }

    @media screen and (max-width: 1020px) {
      .menuHeader {
        display: block;
        width: 95%;
        left: 0;
        top: 55px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }

      .headerMenuContainer {
        display: flex;
        align-items: center;

        .menuOutlinedIcon {
          font-size: 25px;
          margin: 7px 20px;
        }

        .welcomeText {
          img {
            margin-left: 10px;
            margin-top: 0px;
            margin-right: 25px;
          }
        }
      }

      .rightMenuHeader {
        padding-right: 10px;
      }
    }

    @media screen and (max-width: 425px) {
      .menuHeader {
        display: block;
        width: 100%;
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: 20px;
        margin-top: -40px;
        height: -webkit-fill-available;

        li {
          width: 100%;
          display: block;
          border: 1px solid #dde2e4;
          text-align: center;
        }
        :global(.ant-menu-item-selected) {
          text-align: center;
        }
      }
    }
  }
}

.upgradeButton {
  padding: 10px 16px;

  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: var(--text-in-field, #2e2e2e);

  cursor: pointer;
  border-radius: 5px;
  background: var(--upgrade, #ffb02f);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.04);

  opacity: 50%;
  cursor: not-allowed;
}

.profileDropDonwMenu {
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 0;
  min-width: 240px;

  .userData {
    display: flex;
    gap: 12px;
    padding: 18px 0;
    margin: 0 16px;
    border-bottom: 1px solid #dde2e4;
    font-family: "Inter";
    font-style: normal;
    .userPlan {
      display: inline-block;
      height: 21px;
      padding: 4px 6px;
      border-radius: 6px;
      background-color: #dcdcdc;
      border: 1px solid #000;
      font-weight: 700;
      font-size: 11px;
      margin-left: 11px;
      line-height: 19px;
      letter-spacing: -0.01em;
    }
    .userImage {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 45px;
      font-weight: 500;
      font-size: 16px;
      border-radius: 50%;
      background-color: #00b840;
    }

    .userType {
      padding: 0 6px;

      color: #fff;
      font-size: 11px;
      font-weight: 700;
      letter-spacing: -0.11px;

      text-transform: uppercase;

      border-radius: 6px;
      border: 1px solid #fff;
      background: var(--primary-green, #00b840);
    }

    .userDataWrapper {
      display: flex;
      flex-direction: column;
    }
    .userName {
      display: flex;
      align-items: center;
      gap: 5px;

      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
      color: #000000;
    }

    p {
      font-weight: 400;
      margin: 0;
      font-size: 12px;
      line-height: 20px;
      color: #2e2e2e;
    }
  }
  .disabledMenuItem {
    pointer-events: none;
    opacity: 0.5;
  }
  :global(.ant-dropdown-menu) {
    box-shadow: none;
    padding: 0px 16px;
    font-family: "Inter";
  }

  :global(.ant-dropdown-menu-item) {
    padding: 11px 14px !important;
    &:last-child {
      margin-top: 8px;
      border-top: 1px solid #cecece;
      padding-top: 18px !important;
      padding-bottom: 21px !important;
    }
    span {
      display: flex;
      align-items: center;
      gap: 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
      color: #000000;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .itemWithImgIcon {
    span {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
  }
}
