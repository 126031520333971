.software {
  width: 100%;
  height: calc(100vh - 180px);

  display: flex;
  justify-content: center;
  align-items: center;

  &Content {
    max-width: 705px;

    &Title {
      color: #000;
      text-align: center;
      font-size: 34px;
      font-weight: 500;
      line-height: 20px;

      margin: 0 0 35px 0;
    }

    &Desc {
      padding: 33px 48px 55px 48px;

      border-radius: 5px;
      border: 1px solid #e0e0e0;
      background: #fff;

      & > p {
        color: #2e2e2e;
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
}
