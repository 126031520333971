.billing {
  padding: 62px 72px;

  &Cards {
    margin: 0 auto;
    max-width: 1200px;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
  }
}
