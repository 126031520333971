.sectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    text-align: justify;
  }
  img {
    width: 30px;
    height: 30px;
  }
}

.firstSectionElement {
  margin-top: 28px;
  border-top: 1px solid rgb(234, 236, 240);
}
