.table {
  display: grid;
  grid-template-columns: 350px repeat(5, auto);
  grid-template-rows: auto;
  overflow-y: scroll;

  &HeadItem {
    padding-bottom: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--text-in-field, #2e2e2e);
    line-height: 20px;
    border-bottom: 1px solid #cecece;
  }

  &Item {
    padding: 10px 0;
    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    cursor: pointer;

    &Name {
      color: #2e2e2e;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;

      margin-bottom: 5px;
    }

    &Email {
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 290px;
      white-space: nowrap;
    }

    &Avatar {
      width: 35px;
      height: 35px;
      margin-right: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      color: #fff;
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
      border-radius: 50%;
      background-color: #00b840;
    }
  }
}
