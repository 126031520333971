.dashboard {
  padding: 62px 72px;

  &Cards {
    width: fit-content;
    margin: 0 auto 65px auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
  }
}
