.layout {
  height: auto;
  min-height: 100vh;

  display: grid;
  grid-template-columns: 280px 1fr;

  grid-template-rows: 80px 1fr;

  &Header {
    grid-column: 2 / 3;
  }

  &Sidebar {
    grid-row: 1 / 3;
  }
}
