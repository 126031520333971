.modalBg {
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);

  background-color: rgba(5, 5, 5, 0.23);
}

.modal {
  width: 90%;
  padding: 40px 0 15px 0;

  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);

  border-radius: 8px;
  background-color: #ffffff;

  &Title {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 35px;
    font-weight: 500;
    line-height: 20px;
    margin: 0 0 30px;
  }

  &ContentWrap {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto repeat(4, 85px);

    border: 1px solid #e6e9f5;
    border-bottom: none;

    margin-bottom: 10px;
  }

  &ContentItem {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 12px;

    color: var(--black, #000);
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    border-bottom: 1px solid #e6e9f5;
    border-right: 1px solid #e6e9f5;

    &Head {
      padding: 28px;
      border-bottom: 1px solid #e6e9f5;
      border-right: 1px solid #e6e9f5;

      display: flex;
      flex-direction: column;
      justify-content: start;

      &Row {
        border-bottom: 1px solid #e6e9f5;
        border-right: 1px solid #e6e9f5;
        padding: 20px 32px;

        display: flex;
        align-items: center;

        color: var(--black, #000);
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;

        &Asterisk {
          &::after {
            content: "*";
            color: red;
          }
        }
      }

      &Title {
        color: #000;
        font-family: Inter;
        font-size: 25px;
        font-weight: 600;
        margin: 0;

        &Wrap {
          display: flex;
          gap: 16px;
          align-items: center;
          margin-bottom: 12px;
        }

        &Button {
          margin: 0;
          padding: 10px 20px;
          border-radius: 20px;
          background: var(--primary-green, #00b840);

          color: #fff;
          font-family: Inter;
          font-size: 10px;
          font-weight: 500;

          cursor: pointer;
        }
      }

      &Desc {
        color: #858ba0;
        font-family: Inter;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        margin: 0 0 10px;
      }

      &Price {
        color: #000;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0 0 8px;
      }

      &Button {
        padding: 16px 24px;
        margin-top: auto;
        border-radius: 5px;
        text-align: center;

        color: var(--primary-green, #00b840);
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        border: 1px solid var(--primary-green, #00b840);

        cursor: pointer;

        &Disabled {
          color: #808080;
          background: #e6e6e6;
          border: none;
        }

        &Active {
          background-color: #00b840;
          color: #fff;
        }
      }
    }
  }

  &Footer {
    padding-left: 33px;

    &Item {
      color: #858ba0;
      font-family: Inter;
      font-size: 15px;
      line-height: 20px;

      margin: 0 0 10px;

      &::before {
        content: "*  ";
        color: red;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  &CloseIcon {
    position: absolute;
    top: 12px;
    right: 20px;
    cursor: pointer;
  }
}
