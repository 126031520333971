.footerButtonsDiv {
  // margin: 50px 0;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 90px;
  align-items: center;
  margin-top: 60px;
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0;
  padding-inline: 70px;
  // position: absolute;
  width: -webkit-fill-available;
  width: -moz-available;
  bottom: 0px;
  // @media only screen and (min-height: 1440px) {
  //   position: absolute;
  // }
  .leftBtnContainer {
    display: flex;
    img {
      cursor: pointer;
    }
  }
  .nextImg {
    cursor: pointer;
  }
  .nextButton {
    &:disabled {
      background-color: gray;
    }
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    // width: 92px;
    height: 40px;
    font-size: 20px;
    line-height: 27px;
    border: none;
    color: #ffffff;
    background: #00b840;
    border-radius: 5px;
    padding: 6px 20px;
    &:hover {
      color: #ffffff;
      // background: #00b840;
    }
  }

  .reportButton {
    &:disabled {
      background-color: gray;
    }
    font-family: "Inter";
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    // width: 92px;
    height: 40px;
    font-size: 20px;
    line-height: 27px;
    border: 1px solid #00b840;
    color: #00b840;
    background: #fff;
    border-radius: 5px;
    padding: 6px 20px;
    box-sizing: border-box;
    transition:
      background-color 0.3s ease,
      color 0.3s ease,
      border-color 0.3s ease;
    &:hover {
      color: #ffffff;
      background: #00b840;
    }
  }

  .quickNextButton {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    // width: 92px;
    height: 40px;
    font-size: 20px;
    line-height: 27px;
    border: none;
    color: #ffffff;
    background: #00b840;
    border-radius: 5px;
    padding: 6px 20px;
    margin-left: 10px;
    &:hover {
      color: #ffffff;
    }
  }

  .reGenerateImg {
    margin-left: 10px;
  }
  .reGenerate {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    height: 40px;

    color: var(--text-in-field, #2e2e2e);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px; /* 168.75% */

    background-color: #ececf1;

    border-radius: 5px;
    border: none;
    &:hover {
      color: #121212;
      background: #ececf1;
    }
  }

  .stopButton {
    border-radius: 5px;
    background: #ececf1;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 40px;
    padding: 0 15px;

    color: var(--text-in-field, #2e2e2e);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px; /* 168.75% */
    cursor: pointer;
  }

  .twoBtnDiv {
    display: flex;
    justify-content: center;
    gap: 11px;

    & > * {
      & > * {
        max-height: 40px !important;
      }
    }
  }

  .btnDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .twoBtnDiv {
      display: flex;
      justify-content: center;
      gap: 11px;
      .goBtn {
        color: #2e2e2e;
        background: #ececf1;
        border-radius: 5px;
        cursor: pointer;
        border: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        width: 116px;
        height: 40px;
        img {
          margin-top: 1px;
        }
      }
      .saveBtn {
        width: 116px;
        height: 40px;
        color: #2e2e2e;
        white-space: nowrap;
        background: #ececf1;
        border-radius: 5px;
        cursor: pointer;
        border: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;

        line-height: 27px;
      }
    }
    @media only screen and (max-width: 425px) {
      .twoBtnDiv {
        margin-bottom: 0;
        .goBtn {
          height: 40px;
          width: 80px;
        }
        .saveBtn {
          height: 40px;
          width: 90px;
        }
      }
    }
  }
  @media only screen and (max-width: 425px) {
    .nextButtonDiv {
      .nextButton {
        &:disabled {
          background-color: gray;
        }
        height: 40px;
        width: 114px;
      }
    }
  }
  @media only screen and (max-width: 640px) {
    flex-direction: column;

    .leftBtnContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .btnDiv {
      .twoBtnDiv {
        justify-content: space-between;
      }
    }
  }
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modelSelectorDropdown {
  &Label {
    position: relative;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    min-height: 96px;

    &Title {
      font-size: 16px;
    }

    &Desc {
      word-wrap: normal;
      white-space: normal;
      font-weight: normal;
      font-size: 14px;
      padding: 2px 0;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
