.modalBg {
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);

  background-color: rgba(5, 5, 5, 0.23);
}

.modal {
  width: 100%;
  max-width: 720px;

  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);

  border-radius: 8px;
  background-color: #ffffff;

  &Header {
    display: flex;
    justify-content: space-between;

    padding: 25px 25px 17px 18px;
    border-bottom: 1px solid #dde2e4;

    & > p {
      color: #000;
      font-size: 18px;
      font-weight: 500;
      margin: 0;
    }
  }

  &CloseIcon {
    cursor: pointer;
  }

  &InputLabel {
    color: var(--black, #000);
    font-size: 16px;
    margin: 0 0 8px 0;
  }

  &Content {
    padding: 27px 31px 19px 18px;
  }

  &Textarea {
    color: #2e2e2e;
    padding: 16px;
    margin-bottom: 11px;
  }

  &Desc {
    color: #858ba0;
    line-height: 25px;
    margin: 0 0 28px 0;
  }

  &Button {
    width: 95px;
    height: 45px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--primary-white, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    cursor: pointer;
    background: var(--primary-green, #00b840);
  }

  &SuccesText {
    color: var(--primary-green, #00b840);
    line-height: 25px;
    margin-top: 14px;
    margin-bottom: 0;
  }
}
