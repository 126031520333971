.mainSignUpWrraper {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 10px;
  .passwordSuggestionMsg {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #2e2e2e;
  }
  .welcomeWrraper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;

    img {
      width: 60px;
      margin-bottom: 20px;
    }

    h2 {
      font-family: Inter;
      font-size: 31px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: center;
      margin-bottom: 8px;
      margin: 0px;
    }

    p {
      text-align: center;
      font-weight: 400;
      font-size: 20px;
      font-family: "Inter";
      color: #2e2e2e;
    }
  }
  :global(.ant-form-item-label) {
    label {
      font-size: 16px;
    }
  }
  .signUpWrraper {
    .checkBox {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .termsStyle {
      font-family: Inter;
      font-size: 20px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #00b840;
    }

    .signUpBtn {
      background-color: #00b840;
      width: 100%;
      color: #fff;
      height: 45px;
      margin-top: 22px;
      font-size: 20px;
      font-weight: 500;

      &:disabled {
        opacity: 0.6;
      }
      div {
        margin-top: 5px;
      }
    }
  }

  .loginWrraper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;

    p {
      font-weight: 400;
      // font-size: 20px;
      color: #000000;
    }

    span {
      color: #00b840;
      margin-left: 4px;
    }
  }

  .signUpForm {
    :global(.ant-form-item-required) {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }
  }

  :global(.ant-form-item-control) {
    margin-bottom: -10px;
  }
}
