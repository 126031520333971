.container {
  padding: 44px 28px 0 48px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.10000000149011612px;
  text-align: left;

  .topContainer {
    display: flex;
    justify-content: space-between;
  }

  .fnList {
    display: flex;
    gap: 20px;
    padding: 33px 94px 0 48px;
    flex-wrap: wrap;

    &Item {
      width: 30%;
      box-sizing: border-box;

      &Title {
        display: flex;
        align-items: center;
        gap: 12px;
        border: 1px solid #cecece;
        border-radius: 10px;
        background-color: #ffffff;
        padding: 16px 20px 16px 20px;

        //styleName: Text M/Semibold;
        font-family: Inter;
        font-size: 15px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: -0.10000000149011612px;
        text-align: left;

        &Expand {
          margin-left: auto;
          cursor: pointer;
        }
      }

      &Prompts {
        margin-top: 8px;
        border: 1px solid #cecece;
        border-radius: 10px;
        background-color: #ffffff;
        padding: 20px 42px 20px 12px;

        .group {
          padding-bottom: 18px;
          border-bottom: 0.7px solid #cecece;
          box-sizing: border-box;
          margin-bottom: 18px;

          .label,
          .subLabel {
            cursor: pointer;
          }

          .label {
            font-family: Inter;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
          }
          .subLabel {
            margin-left: 24px;
            margin-top: 10px;
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
        }
      }
    }
  }

  .editPromptContent {
    margin: 10px;

    .editPromptHeader {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .updatePromptSuccess {
        height: 46px;
        border-radius: 5px;
        border: 1px solid #e0e0e0;
        background: #ffffff;
        font-size: 12px;
        line-height: 46px;
        vertical-align: middle;
        display: table-cell;
        padding: 0 44px;
        position: relative;

        > img {
          position: absolute;
        }
        > span {
          display: inline-block;
        }
        .greenCheck {
          display: inline-block;
          top: 14px;
          left: 12px;
        }

        .cross {
          top: 18px;
          right: 11px;
          cursor: pointer;
        }
      }
    }

    > textarea {
      margin-top: 39px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      padding: 25px 43px 50px 40px;
      border-radius: 5px;
      border: 1px solid #e0e0e0;
    }

    .editPromptFooter {
      margin-top: 31px;
      display: flex;

      button {
        cursor: pointer;
        border-radius: 5px;
        margin-right: 14px;
        border: none;
        height: 40px;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        padding: 10px 16px;
      }

      button:disabled {
        opacity: 0.6;
      }

      .savePrompt {
        height: 40px;
        background: #00b840;
        color: white;
      }

      .goBack {
        padding: 0; /* Adjust button padding if needed */
        background: none; /* Remove background if needed */
        border: none; /* Remove border if needed */
      }
    }
  }
}
